import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import Tilty from 'react-tilty';
import { Link } from 'react-router-dom';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const NuestrasPoliticas = () => {
    return (
        <>
            <SEO title="Política Ambiental y de Calidad" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Política Ambiental y de Calidad"
                paragraph='
                <p>Fradealco SA dedicada a: "<i>Destilación, fraccionamiento, elaboración, comercialización y distribución de productos químicos alimenticios, cosméticos, medicinales y domisanitarios</i>", tiene un compromiso ambiental y de calidad, el cual es ofrecer un producto de excelente calidad partiendo en un proceso de mejora continua; respaldado con gente capacitada, en un entorno seguro y con una adecuada relación costo beneficio.</p>

                <p>El compromiso de <strong>FRADEALCO S.A.</strong> se basa en:</p>
                <ul>
                    <li>Llevar a cabo nuestro trabajo dentro de un entorno de gestión que garantice una mejora continua en nuestros procesos, mediante una revisión periódica de nuestros objetivos establecidos del Sistema de Gestión.</li>
                    <li><strong>Proteger el medio ambiente</strong> y nuestro entorno estableciendo medidas orientadas a la prevención de cualquier tipo de contaminación que pudiera originarse por operaciones debidas a nuestra actividad.</li>
                    <li>Promover una comprensión y difusión de nuestra política de calidad y gestión ambientals dentro de nuestra EMPRESA y aquellos que trabajen en nombre de ella.</li>
                        <ol>
                            <li>Controlar de manera efectiva todos nuestros procesos teniendo énfasis en: Calidad y seguridad de nuestros productos.</li>
                            <li>Eficiencia y eficacia de nuestros servicios/productos</li>
                            <li>Relaciones con nuestros clientes.</li>
                            <li>Cuidado del Medioambiente</li>
                        </ol>
                    <li>Cumplir con todos los requisitos, ya sean estos legales u otros que nos sean aplicables en razón de nuestra actividad.</li>
                </ul>'
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                <div style={{textAlign:'center'}}>
                    <Link to='/buenas-practicas-manofactura'a>
                        <Tilty perspective={4000} reset={false}>
                            <img src={process.env.PUBLIC_URL + '/images/others/bpmNewClaypole.png'} />
                        </Tilty>
                    </Link>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default NuestrasPoliticas;