import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>Inicio</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/productos"}>
            Productos <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/productos/medicinales"}>Medicinales</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/productos/domisanitario"}>Domisanitario</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/productos/perfumeria"}>Perfumería</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/productos/alimenticios"}>Alimenticios</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/productos/industrias"}>Industrias</Link>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/quienes-somos"}>
            Institucional <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/quienes-somos"}>Quienes somos</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/mision-compromiso"}>Nuestra misión y compromiso</Link>
            </li>
            {/* <li><Link to={process.env.PUBLIC_URL+''}>Nuestras Políticas</Link></li> */}
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/recursos-humanos"}>Recursos Humanos</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Nuestras Políticas<FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/nuestras-politicas"}>Política de Calidad y Medio Ambiente</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/politica-rse"}>
                Política de RSE
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Calidad <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/buenas-practicas-manofactura"}>
                Buenas Prácticas de Manufactura (BPM)
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/garantia-calidad"}>Garantía de calidad</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/controles-inscripciones"}>Controles e inscripciones</Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/iso9001-2015_iso14001-2015"}>ISO 9001/2015 E ISO 14001/2015</Link>
            </li> */}
            
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contacto"}>Contacto</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
